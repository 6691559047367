//kod by szymon
// window.addEventListener('heyflow-screen-view', (event) => {
//     // console.log('heyflow screen view:', event.detail);
// });

// window.addEventListener('heyflow-submit', (event) => {
//     // console.log('heyflow submit:', event.detail);
    
//     console.log('---e', event)
    
//     let ADD_score = event.detail.fieldsSimple.ADD_score
//     let Hyper_score = event.detail.fieldsSimple.Hyper_score 

//     // Zmienna, w której przechowamy wynik
//     let result = '';

//     // Sprawdzamy typ mieszany jako pierwszy, ponieważ oba wyniki są wysokie
//     if (ADD_score >= 6 && Hyper_score >= 9) {
//         result = "Typ mieszany (najczęstszy)";
//     }
//     // Sprawdzamy typ z przewagą zaburzeń uwagi (ADD)
//     else if (ADD_score >= 6 && Hyper_score < 9) {
//         result = "Typ z przewagą zaburzeń uwagi (ADD)";
//     }
//     // Sprawdzamy typ z przewagą nadpobudliwości i impulsywności
//     else if (Hyper_score >= 9 && ADD_score < 6) {
//         result = "Typ z przewagą nadpobudliwości i impulsywności";
//     }
//     // Brak ADHD
//     else if (ADD_score < 6 && Hyper_score < 6) {
//         result = "Brak ADHD";
//     }
    
//     console.log('--result', result)

//     // Wyświetlenie wyniku
//     const resultElement = document.getElementById('adhdResult');
//     if (resultElement) {
//         resultElement.innerText = result;
//     } else {
//         console.error("Nie znaleziono elementu wynikowego 'adhdResult'");
//     }
// });

//kod by Szymon

// document.addEventListener('DOMContentLoaded', () => {
//     // Przykładowe wartości ADD_score i Hyper_score
//     let ADD_score = 0;  // Zmienna ADD_score
//     let Hyper_score = 0;  // Zmienna Hyper_score
    
//     console.log('--scores', ADD_score, ' - ', Hyper_score)

//     // Zmienna, w której przechowamy wynik
//     let result = '';

//     // Sprawdzamy typ mieszany jako pierwszy, ponieważ oba wyniki są wysokie
//     if (ADD_score >= 6 && Hyper_score >= 9) {
//         result = "Typ mieszany (najczęstszy)";
//     }
//     // Sprawdzamy typ z przewagą zaburzeń uwagi (ADD)
//     else if (ADD_score >= 6 && Hyper_score < 9) {
//         result = "Typ z przewagą zaburzeń uwagi (ADD)";
//     }
//     // Sprawdzamy typ z przewagą nadpobudliwości i impulsywności
//     else if (Hyper_score >= 9 && ADD_score < 6) {
//         result = "Typ z przewagą nadpobudliwości i impulsywności";
//     }
//     // Brak ADHD
//     else if (ADD_score < 6 && Hyper_score < 6) {
//         result = "Brak ADHD";
//     }
    
//     console.log('--result', result)

//     // Wyświetlenie wyniku
//     const resultElement = document.getElementById('adhdResult');
//     if (resultElement) {
//         resultElement.innerText = result;
//     } else {
//         console.error("Nie znaleziono elementu wynikowego 'adhdResult'");
//     }
// });

//kod by Wojciech
const addVars = ["pyt1", "pyt2", "pyt3", "pyt4", "pyt5", "pyt6", "pyt16", "pyt18"];
const hppVars = ["pyt7", "pyt8", "pyt9", "pyt10", "pyt11", "pyt12", "pyt13", "pyt14", "pyt15", "pyt17", "pyt19"];

// Function to calculate sum based on the variables
function calculateSum(variables, data) {
    let passData = JSON.parse(data[0]);
    return passData
        .filter(item => variables.includes(item.variable))
        .reduce((sum, item) => sum + parseInt(item.value), 0);
}
 const allValues = [];
  window.addEventListener('heyflow-change', (event) => {
    const fields = 21 //ilosc pol gdzie sa wpisywane zmienne w trakcie ankiety
    if(event.detail.fields.length === 21 ){
        allValues.push(JSON.stringify(event.detail.fields));
    }
 });
 
  window.addEventListener('heyflow-screen-view', (event) => {
      if(event.detail.screenName == "result"){
         
    // Select the section element
          const dynamicText = document.querySelector("#text-block-id-e8af6863 > div > p:nth-child(2)")
          const adhdType = document.querySelector("#text-block-id-8c2ee08e > div > h4:nth-child(2) > span");
          
          
         const addSum = calculateSum(addVars,allValues);
         const hppSum = calculateSum(hppVars,allValues);
         const resultElement = document.querySelector('.adhd');
        let result;
        let  description;
switch (true) {
    case (addSum >= 6):
        if (hppSum >= 9) {
            result = "Typ mieszany (najczęstszy)";
            description = "Dzieci z tym typem ADHD mają problem z koncentracją i nadpobudliwością co może powodować trudności zarówno w nauce, jak i w relacjach społecznych, co zwiększa ryzyko izolacji, problemów emocjonalnych i niskiej samooceny.";
        } else {
            result = "Typ z przewagą zaburzeń uwagi (ADD)";
            description = "Dzieci z tym typem ADHD często mają trudności z koncentracją, co może prowadzić do zaległości w nauce, niskiej samooceny oraz frustracji z powodu braku sukcesów akademickich.";
        }
        break;

    case (addSum < 6):
        if (hppSum >= 9) {
            result = "Typ z przewagą nadpobudliwości i impulsywności";
            description = "Dzieci z tym typem ADHD wykazują nadmierną aktywność i impulsywność co może prowadzić do problemów z zachowaniem, trudności w relacjach społecznych, a także ryzykownych zachowań, które mogą być niebezpieczne.";
        } else {
            result = "Możliwy brak ADHD";
            description = "Osoby nie wykazują istotnych objawów ADHD w żadnym z obszarów, zarówno ADD, jak i HPP. Nie oznacza to, że Twoje dziecko nie ma ADHD. Zapoznaj się z kolejną stroną, by dowiedzieć się więcej.";
        }
        break;

    default:
        result = "Nieokreślony wynik";
        description = "Nie udało się określić wyniku na podstawie dostarczonych danych.";
}

// Set the result in the HTML element
adhdType.innerText = result;
resultElement.innerText = result;
dynamicText.innerText = description;
      }
 });





